import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Form } from '@bpmn-io/form-js'
import '@bpmn-io/form-js/dist/assets/form-js.css'
import FileUploadModule from '../custom-file-render'
import FileDownloadModule from '../custom-file-download'
import CustomSelectModule from '../custom-http-bound-connector'
import { Button, ExportTableDataModal } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import exportTableData from '../../utils/exportTableData'
import { useResponsive } from '../../context/ResponsiveContextProvider'

type FormViewerProps = {
  schema: any
  data?: any
  readOnly?: boolean
  onSubmit?(value: any): void
  onSave?(value: any): void
  onDeescalate?(data: any): void
}

type FormStateData = {
  initialData?: any
  data?: any
  properties?: any
  errors?: any
  schema?: any
}

const FormViewer = forwardRef<unknown, FormViewerProps>(
  ({ schema, data, readOnly, onDeescalate, onSubmit, onSave }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const form = useRef<Form | null>(null)
    const [formStateData, setFormStateData] = useState<FormStateData | null | undefined>()
    const [tableData, setTableData] = useState<any>({})
    const [isExportOpen, setExportOpen] = useState(false)
    const { t } = useTranslation()
    const { isMobile } = useResponsive()

    useEffect(() => {
      form.current = new Form({
        container: containerRef.current,
        additionalModules: [FileUploadModule, FileDownloadModule, CustomSelectModule],
      })
      const load = async () => {
        try {
          await form.current?.importSchema(schema, data)
        } catch (err) {
          console.error('failed to import form', err)
        }
      }
      load()
      form.current?.setProperty('readOnly', readOnly)
      //localStorage.setItem('form', JSON.stringify(form.current._getState()))
      data ? localStorage.setItem('formData', JSON.stringify(data)) : JSON.stringify({})
      console.log('FORM', form)
      setFormStateData(form.current._getState())
      return () => form?.current?.destroy()
    }, [schema, data, readOnly])

    useImperativeHandle(ref, () => ({
      deescalateTask() {
        if (!form.current) return
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { data } = form.current?.submit()
        onDeescalate?.(data)
      },
      submitForm() {
        if (!form.current) return
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { data, errors } = form.current?.submit()
        if (!Object.keys(errors).length) {
          onSubmit?.(data)
        }
      },
      saveForm() {
        if (!form.current) return
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { data } = form.current?.submit()
        onSave?.(data)
      },
    }))

    useEffect(() => {
      const tables = formStateData?.schema?.components?.filter((component: any) => component?.type == 'table')
      const dataForTables = tables?.reduce((acc, t) => {
        const dataSource = t?.dataSource as string
        const key = dataSource?.startsWith('=') ? dataSource.slice(1) : dataSource
        acc[key] = formStateData?.data?.[key]
        return acc
      }, {})
      setTableData(dataForTables)
    }, [formStateData])

    const handleExport = async (exportOptions: { exportCsv: boolean; exportExcel: boolean }) => {
      if (!(tableData && typeof tableData === 'object' && Object.keys(tableData).length > 0)) return
      exportTableData({
        xlsFormat: exportOptions.exportExcel,
        csvFormat: exportOptions.exportCsv,
        tablePayload: tableData,
      })
    }

    return (
      <div>
        {!isMobile && tableData && typeof tableData === 'object' && Object.keys(tableData).length > 0 && (
          <div data-testid='export-button'>
            <Button className='border border-blue-500 text-blue-500' onClick={() => setExportOpen(true)}>
              {t('Common.export')}
            </Button>
          </div>
        )}
        <div ref={containerRef} />
        {isExportOpen && (
          <ExportTableDataModal
            open={isExportOpen}
            setOpen={setExportOpen}
            onConfirm={(val) => {
              handleExport && handleExport(val)
              setExportOpen(false)
            }}
            selectableRows={false}
          />
        )}
      </div>
    )
  },
)

FormViewer.displayName = 'FormViewer'

export default FormViewer
