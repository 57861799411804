import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { Dispatch, FC, SetStateAction, useRef } from 'react'
import { Button, SpinnerIcon } from '@sistemiv/s-components'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTaskStartFrom } from '../../repositories'
import ModalDialog from '../ModalDialog'
import FormViewer from '../../extensions/form/FormViewer'
import { useProcessInstanceVariables } from '../../repositories/process-instances/process-instance-variables.repository'

type UserTaskFormModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  taskId: string
  processInstanceId: string
  handleBulkComplete: (data: any) => void
}

const UserTaskFormModal: FC<UserTaskFormModalProps> = ({
  open,
  setOpen,
  handleBulkComplete,
  taskId,
  processInstanceId,
}) => {
  const { org } = useParams()
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null)
  const formRef = useRef<any>(null)
  const { data: variablesData, isLoading: isVariablesLoading } = useProcessInstanceVariables(processInstanceId, taskId)
  const { data: formFields, isLoading: isFormLoading, isFetching: isFormFetching } = useTaskStartFrom({ id: taskId })
  const { t } = useTranslation()

  const handleComplete = async (data: any) => {
    const form = {}
    if (formFields) {
      const formKeys = formFields.components.filter((f) => f.type.includes('file')).map((f) => f.key)
      Object.keys(data).forEach((oldKey) => {
        if (formKeys.includes(oldKey)) {
          try {
            const oldValue = JSON.parse(data[oldKey])
            form[oldKey] = {
              value: oldValue?.[0]?.id,
              valueInfo: {
                fileName: oldValue?.[0]?.fileName,
                mimeType: oldValue?.[0]?.mimeType,
                encoding: oldValue?.[0]?.encoding,
              },
            }
          } catch {
            console.log('skip')
          }
        } else {
          form[oldKey] = { value: data[oldKey] }
        }
      })
    }
    await handleBulkComplete(form)
  }

  return (
    <ModalDialog isOpen={open} initialRef={cancelButtonRef}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between mb-5'>
        {t('ProcessInstance.userTaskModal')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      {isFormLoading || isFormFetching || isVariablesLoading ? (
        <div className='w-full flex items-center justify-center py-6'>
          <SpinnerIcon className='w-6 h-6 text-sky-500' />
        </div>
      ) : (
        <>
          <div className='py-2 px-3 mb-5 border-l-4 border-sky-500 bg-sky-200 text-sky-500'>
            {t('ProcessInstance.bulkCompleteMsg')}
          </div>
          {formFields && formFields?.components?.length > 0 && (
            <FormViewer
              ref={formRef}
              data={variablesData}
              schema={{
                ...formFields,
                components: formFields.components.map((f) => {
                  if (f.type.includes('file')) {
                    return {
                      ...f,
                      organization: org,
                    }
                  }
                  if (f.type.includes('dynamiclist')) {
                    return {
                      ...f,
                      components: f.components.map((component) => {
                        if (component.type.includes('file')) {
                          return {
                            ...component,
                            organization: org,
                          }
                        } else return component
                      }),
                    }
                  }
                  return f
                }),
              }}
              onSubmit={(data) => {
                handleComplete(data)
              }}
            />
          )}
        </>
      )}
      <div className='flex justify-end items-center gap-x-4' data-testid='create-process-buttons'>
        <Button className='text-slate-600' ref={cancelButtonRef} onClick={() => setOpen(false)}>
          {t('Common.cancel')}
        </Button>
        <Button
          className='bg-sky-500 text-white disabled:bg-slate-400'
          // loading={isTaskCompleting}
          // disabled={isTaskCompleting}
          onClick={() =>
            formFields && formFields?.components?.length > 0 ? formRef?.current?.submitForm() : handleComplete(null)
          }
        >
          {t('UserTasks.complete')}
        </Button>
      </div>
    </ModalDialog>
  )
}

export default UserTaskFormModal
