import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import classNames from '../../classNames'
import { SortIcon, Button, SortIconFlipped, SpinnerIcon, LinkToIcon, Base64SVG } from '@sistemiv/s-components'
import SubprocessesTab from './SubprocessesTab'
import AttachmentsTab from './process-instance-attachments/AttachmentsTab'
import { mapProcessInstance } from '../../utils/mappers'
import { useProcessInstance, useProcessInstanceTaskRetry } from '../../repositories'
import { useProcessDiagram } from '../../repositories/process-instances/process-diagram.repository'
import ProcessInstanceInfoTab from './process-instance-info-tab/ProcessInstanceInfoTab'
import ProcessInstanceInfoTabClosed from './process-instance-info-tab/ProcessInstanceInfoTabClosed'
import useFormatVariableData from '../../repositories/process-instances/hooks/useFormatVariableData'
import { Incident } from '../../models/ProcessInstance'
import HistoryTab from './process-instance-history/HistoryTab'
import { useListAllUsersInOrg } from '../../repositories/settings/processes/processes/list-all-users-in-org.repository'
import { useCurrentProcessDefinition } from '../../repositories/process-instances/process-definition-selected.repository'
import VariablesTab from './process-instance-variables-tab/VariablesTab'
import DiagramPreview from './process-instance-details/DiagramPreview'
import BottomInfoPreview from './process-instance-details/BottomInfoPreview'
import IncidentReport from './process-instance-details/incident-report/IncidentReport'
import { useProcessInstanceTaskUpdate } from '../../repositories/process-instances/mutations/process-instance-task-update'
import { useDiagramPath } from '../../repositories/analytics/diagram-path.repository'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { SignalRContext } from '../../pages/Dashboard'

type Tab = {
  key: 'details' | 'variables' | 'history' | 'subprocesses' | 'attachments'
  value: string
}
const ProcessInstance: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { org, type, id } = useParams()
  const [instanceData, setInstanceData] = useState<any>()
  const [isInfoOpen, setIsInfoOpen] = useState(true)
  const [activityPreview, setActivityPreview] = useState<any>(null)
  const [incidentPreview, setIncidentPreview] = useState<Incident | undefined>(undefined)
  const { data: instanceRawData, isLoading } = useProcessInstance()
  const [activeTab, setActiveTab] = useState<Tab>({ key: 'details', value: t('ProcessInstance.tabs.details') })
  const [sortOrder, setSortOrder] = useState<string>('asc')
  const { data: diagramData, isLoading: isDiagramLoading } = useProcessDiagram({
    processDefinitionId: instanceRawData?.processInstance.processDefinitionId,
    processInstanceId: instanceRawData?.processInstance.id,
  })
  const { data: users } = useListAllUsersInOrg()
  const { data: currentProcess } = useCurrentProcessDefinition({ org, processDefinitionKey: type })
  const { mutate: retryTask, isPending: isRetryingTask } = useProcessInstanceTaskRetry()
  const { mutate: editTask } = useProcessInstanceTaskUpdate()
  const { variableTabs, variableData } = useFormatVariableData()
  const queryClient = useQueryClient()
  const { data: pathActivities, isLoading: pathIsLoading } = useDiagramPath()

  const tabs = useMemo(() => {
    const initialTabs: Tab[] = [
      { key: 'details', value: t('ProcessInstance.tabs.details') },
      { key: 'variables', value: t('ProcessInstance.tabs.variables') },
      { key: 'history', value: t('ProcessInstance.tabs.history') },
      // t('ProcessInstance.tabs.linkedProcesses'),
      // t('ProcessInstance.tabs.comments'),
      // t('ProcessInstance.tabs.incidents'),
    ]
    instanceRawData?.processInstance?.subprocesses?.length > 0 &&
      initialTabs.splice(2, 0, { key: 'subprocesses', value: t('ProcessInstance.tabs.subprocesses') })
    instanceRawData?.processInstance?.attachments &&
      Object.keys(instanceRawData?.processInstance?.attachments)?.length > 0 &&
      initialTabs.splice(3, 0, { key: 'attachments', value: t('ProcessInstance.tabs.attachments') })
    return initialTabs
  }, [instanceRawData, t])

  useEffect(() => {
    setActiveTab((prev) => tabs.find((tab) => tab.key === prev.key)!)
  }, [type, id, tabs, t])

  useEffect(() => {
    if (!instanceRawData) return
    setInstanceData(mapProcessInstance(instanceRawData.processInstance, [], users?.users))
  }, [instanceRawData, users])

  const handleIsInfoOpen = () => {
    setIsInfoOpen(!isInfoOpen)
  }

  useEffect(() => {
    if (!id || !SignalRContext.connection || SignalRContext.connection?.state !== 'Connected') return
    console.log('effect')
    console.log(id)
    console.log(SignalRContext.connection)
    SignalRContext.invoke('add-filter', 'processInstanceId', id)
    SignalRContext.invoke('add-filter', 'organizationCode', org)

    return () => {
      SignalRContext.invoke('remove-filter', 'processInstanceId', id)
      SignalRContext.invoke('remove-filter', 'organizationCode', org)
    }
  }, [id, org])

  SignalRContext.useSignalREffect(
    'sync',
    //@ts-ignore
    (eventName, data) => {
      if (
        ['ProcessInstanceDueDateChangedEvent', 'ProcessInstancePriorityChangedEvent'].includes(eventName) &&
        instanceRawData?.processInstance?.id === data.ProcessInstanceId
      ) {
        console.log('update')
        const newData =
          eventName === 'ProcessInstanceDueDateChangedEvent'
            ? { ...instanceRawData.processInstance, dueDate: data.DueDate }
            : { ...instanceRawData.processInstance, priority: data.Priority }
        console.log(instanceRawData)
        console.log({ ...instanceRawData, processInstance: { ...newData } })
        queryClient.setQueryData(['process-instances', org, id], {
          ...instanceRawData,
          processInstance: { ...newData },
        })
      }
      if (
        ['ServiceTaskInstanceStartedEvent', 'UserTaskStartedEvent', 'ServiceTaskInstanceFailedEvent'].includes(
          eventName,
        ) &&
        instanceRawData?.processInstance?.id === data.ProcessInstanceId &&
        ['details', 'variables', 'attachments'].includes(activeTab.key)
      ) {
        const getData = setTimeout(() => {
          if (activeTab.key === 'details') {
            console.log('invalidate diagram')
            queryClient.invalidateQueries({
              queryKey: ['process-instances', org, id],
            })
            queryClient.invalidateQueries({
              queryKey: [
                'process-instances-diagram',
                org,
                instanceRawData?.processInstance.processDefinitionId,
                instanceRawData?.processInstance.id,
              ],
            })
            queryClient.invalidateQueries({
              queryKey: ['diagram-path', org, id],
            })
          }
          if (activeTab.key === 'attachments') {
            console.log('invalidate attachments')
            queryClient.invalidateQueries({
              queryKey: ['process-instances', org, id],
            })
          }
          if (activeTab.key === 'variables') {
            console.log('invalidate variables')
            queryClient.invalidateQueries({
              queryKey: ['process-instances', org, id],
            })
            queryClient.invalidateQueries({
              queryKey: ['variables', org, type, 'variables'],
            })
          }
        }, 1500)
        return () => clearTimeout(getData)
      }
      if (
        ['UserTaskUpdatedEvent'].includes(eventName) &&
        instanceRawData?.processInstance?.activities?.find((a) => a.activityInstanceId === data.ActivityInstanceId)
      ) {
        const newData = {
          ...instanceRawData.processInstance,
          activities: instanceRawData.processInstance.activities.map((act) =>
            act.activityInstanceId === data.ActivityInstanceId
              ? { ...act, assignee: data.AssigneeId, dueDate: data.DueDate, priority: data.Priority }
              : act,
          ),
        }
        queryClient.setQueryData(['process-instances', org, id], {
          ...instanceRawData,
          processInstance: { ...newData },
        })
        if (activityPreview && activityPreview.activityInstanceId === data.ActivityInstanceId)
          setActivityPreview({
            ...activityPreview,
            dueDate: data.DueDate,
            priority: data.Priority,
            assignee: data.AssigneeId,
          })
      }
      if (
        ['ProcessInstanceStartedEvent'].includes(eventName) &&
        data?.ParentProcessInstanceId &&
        id === data.ParentProcessInstanceId
      ) {
        queryClient.invalidateQueries({
          queryKey: ['process-instances', org, id],
        })
      }
    },
    [],
  )

  const handleClick = (id: string) => {
    const getActivityInstanceId = (id: string) => {
      const activity = instanceRawData?.processInstance?.activities?.find(
        (a) => a.activityName === id || a.activityId === id || a.activityInstanceId === id,
      )
      return activity?.activityInstanceId
    }
    const clicked = instanceRawData?.processInstance?.activities?.find(
      (a) => a.activityId === id || a.activityName === id || a.activityInstanceId === id,
    )

    const clickedIncident: Incident = instanceRawData?.processInstance?.incidents?.find(
      (incident: Incident) => getActivityInstanceId(id) === incident.activityInstanceId,
    )

    setIncidentPreview(clickedIncident)
    setActivityPreview(clicked)
  }

  const memoizedClick = useCallback(handleClick, [instanceRawData])

  const handleRestartIncident = () => {
    if (!incidentPreview) return
    retryTask({ taskId: incidentPreview.activityInstanceId })
  }

  const handleEditTask = (actionString: string, body: any, taskId: string) => {
    editTask({ actionString, taskId, body })
  }

  if (isLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
      </div>
    )

  return (
    <div className='w-full h-full flex flex-col '>
      <div className='flex justify-between items-center py-3 px-8 border-b border-gray-300 w-full grow-0'>
        <div className='flex items-center gap-x-7'>
          <button onClick={() => navigate(-1)} className='rounded-full hover:bg-sky-50 p-2'>
            <ArrowLeftIcon className='w-6 h-6 text-gray-400' />
          </button>
          <div className='flex items-center gap-x-3'>
            <Base64SVG base64image={currentProcess?.icon ?? ''} className='[&>svg]:w-5 [&>svg]:h-5' />
            <div>/</div>
            {instanceData && <div>{instanceData?.ProcessInstanceCode?.value}</div>}
            {activityPreview &&
              activityPreview?.canAccessUserTask &&
              instanceRawData?.processInstance?.activities.find((a) => a.activityId === activityPreview?.activityId)
                ?.activityType === 'userTask' && (
                // <Link
                //   to={`/${org}/tasks?id=${
                //     diagramData.currentActivities.find(
                //       (activity) => activity.activityId === activityPreview?.activityId,
                //     )?.activityInstanceId
                //   }`}
                // >
                <Link
                  to={`/${org}/tasks?search=${instanceData?.ProcessInstanceCode?.value}&id=${activityPreview?.activityInstanceId}`}
                >
                  <LinkToIcon className='w-4 h-4 fill-blue-500' />
                </Link>
              )}
          </div>
        </div>
      </div>
      <div className='flex flex-row grow overflow-hidden'>
        <div className='px-8 flex flex-col grow overflow-auto overflow-y-auto'>
          <div className='sticky top-0 z-20 flex justify-start gap-x-8 text-slate-600 bg-white pt-8'>
            <div className='absolute bottom-0 w-full h-0.5 z-0 bg-gray-300 ' data-testid='tabs-panel' />
            {tabs?.map((tab) => (
              <div
                key={`tab-${tab?.key}`}
                onClick={() => setActiveTab(tab)}
                data-testid={`tab-${tab?.key}`}
                className={classNames(
                  'relative z-20 py-3 cursor-pointer border-b-2  whitespace-nowrap',
                  activeTab?.key === tab?.key
                    ? 'border-blue-500 text-blue-500 hover:border-blue-500'
                    : 'border-gray-300 hover:border-black',
                )}
              >
                {tab && tab?.value}
              </div>
            ))}
            {[t('ProcessInstance.tabs.comments'), t('ProcessInstance.tabs.history')].includes(activeTab.value!) && (
              <div className='w-full inline-flex justify-end items-center'>
                <Button
                  onClick={() =>
                    setSortOrder((old) => {
                      return old === 'asc' ? 'dsc' : 'asc'
                    })
                  }
                >
                  {sortOrder === 'asc' ? (
                    <SortIcon className='w-4 h-4 fill-black' />
                  ) : (
                    <SortIconFlipped className='w-4 h-4 fill-black' />
                  )}
                </Button>
              </div>
            )}
          </div>
          <div className='pt-8 pb-2 grow'>
            {activeTab.key === 'details' ? (
              <>
                <div className='w-full h-full' data-testid='diagram-tab'>
                  {(isDiagramLoading || pathIsLoading) && (
                    <div className='w-full flex justify-center pt-5'>
                      <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
                    </div>
                  )}
                  {diagramData && pathActivities && (
                    <>
                      <DiagramPreview
                        diagramXML={diagramData.bpmn20Xml}
                        highlightClass={'highlight'}
                        incidents={instanceRawData?.processInstance?.incidents ?? []}
                        activities={instanceRawData?.processInstance?.activities ?? []}
                        selectedId={activityPreview?.activityId}
                        onClick={memoizedClick}
                        pathActivities={pathActivities}
                        completed={instanceRawData?.processInstance?.completed}
                      />
                      <div className='pt-3'>
                        {!!activityPreview && (
                          <BottomInfoPreview
                            incident={incidentPreview}
                            activityName={activityPreview.activityName}
                            dueDate={activityPreview.dueDate}
                            assignee={activityPreview.assignee}
                            priority={activityPreview.priority}
                            onRestartIncident={() => handleRestartIncident()}
                            taskRestarting={isRetryingTask}
                            activityId={activityPreview.activityInstanceId}
                            onEdit={handleEditTask}
                            activityType={activityPreview.activityType}
                          />
                        )}
                        {incidentPreview && (
                          <IncidentReport
                            errorCode={incidentPreview.code}
                            errorDescription={incidentPreview.description}
                            errorMessage={incidentPreview.message}
                            failedAt={incidentPreview.failedAt}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : activeTab.key === 'variables' ? (
              <div data-testid='variables-tab'>
                <VariablesTab variableData={variableData} variableTabs={variableTabs} />
              </div>
            ) : activeTab.key === 'subprocesses' ? (
              <SubprocessesTab subprocesses={instanceRawData?.processInstance?.subprocesses} users={users?.users} />
            ) : // ) : activeTab === t('ProcessInstance.tabs.linkedProcesses') ? (
            // <LinkedProcessesTab />
            activeTab.key === 'attachments' ? (
              <AttachmentsTab />
            ) : // ) : activeTab === t('ProcessInstance.tabs.comments') ? (
            // <CommentsTab />
            activeTab.key === 'history' ? (
              <HistoryTab sortOrder={sortOrder} />
            ) : (
              // ) : activeTab === t('ProcessInstance.tabs.incidents') ? (
              // <IncidentsTab incidents={instanceData.incidents.value} />
              // )
              <div></div>
            )}
          </div>
        </div>
        {isInfoOpen ? (
          <ProcessInstanceInfoTab
            onClick={handleIsInfoOpen}
            code={{ name: instanceData?.ProcessInstanceCode?.value, color: instanceData?.Activity?.solidColor }}
            createdDate={instanceRawData?.processInstance.created}
            dueDate={instanceRawData?.processInstance.dueDate}
            description={instanceRawData?.processInstance.description}
            priority={instanceRawData?.processInstance.priority}
            processInstanceId={instanceRawData?.processInstance.id}
            resolution={instanceRawData?.processInstance.resolution}
            createdBy={instanceRawData?.processInstance.userId}
            completedAt={instanceRawData?.processInstance.completedAt}
            completed={instanceRawData?.processInstance.completed}
          />
        ) : (
          <ProcessInstanceInfoTabClosed onClick={handleIsInfoOpen} />
        )}
      </div>
    </div>
  )
}

export default ProcessInstance
