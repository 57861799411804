import React, { FC, useState, useEffect, useMemo } from 'react'
import { Button, DeleteModal, SpinnerIcon, TabPanel, Tabs, PaginationController } from '@sistemiv/s-components'
import { PlusIcon } from '@heroicons/react/24/outline'
import classNames from '../../../../classNames'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useWebhooks } from '../../../../repositories/settings/processes/webhooks'
import { useAddWebhook } from '../../../../repositories/settings/processes/webhooks/mutation/add-webhook.mutation'
import { useDeleteWebhook } from '../../../../repositories/settings/processes/webhooks/mutation/delete-webhook.mutation'
import EditAccessModal from './modals/EditAccessModal'
import { useUpdateWebhookStatus } from '../../../../repositories/settings/processes/webhooks/mutation/webhook-update-status.mutation'
import IncidentsTab from './IncidentsTab'
import AddWebhookModal from './modals/AddWebhookModal'
import EditWebhookModal from './modals/EditWebhookModal'
import Table from './Table'
import { TableRow } from '@sistemiv/s-components/dist/esm/components/table/Table'

type Tab = {
  key: 'config' | 'incidents'
  value: string
}
const WebhooksPage: FC = () => {
  const { org } = useParams()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(20)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editAccessModalOpen, setEditAccessModalOpen] = useState(false)
  const { t } = useTranslation()
  const tabs: Tab[] = useMemo(
    () => [
      { key: 'config', value: t('Settings.tabs.configuration') },
      { key: 'incidents', value: t('Settings.tabs.incidents') },
    ],
    [t],
  )
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])
  const { mutate: addWebhook, isPending: addingWebhook } = useAddWebhook()
  const { mutate: updateWebhookStatus, isPending: updatingWebhook } = useUpdateWebhookStatus()
  const [search, setSearch] = useState('')
  const {
    data: webhooks,
    isLoading,
    isFetching,
    isPending,
  } = useWebhooks({
    page: page,
    size: size,
    search: search,
  })
  const [editingWebhook, setEditingWebhook] = useState<any | undefined>(undefined)
  const { mutateAsync: deleteWebhookMutation, isPending: removingWebhook } = useDeleteWebhook()

  useEffect(() => {
    setActiveTab((prev) => tabs.find((tab) => tab.key === prev.key)!)
  }, [t, tabs])

  const newRows = webhooks?.webhooks?.map((webhook) => {
    const retval: TableRow = {
      id: {
        type: 'value',
        value: webhook?.id,
      },
      name: {
        type: 'value',
        value: webhook?.name,
      },
      url: {
        type: 'value',
        value: webhook?.url,
        copyBtn: true,
      },
      authType: {
        type: 'value',
        value: webhook?.authType,
      },
      enabled: {
        type: 'value',
        value: webhook?.enabled,
      },
    }

    return retval
  })

  const headers = [
    {
      accessor: 'name',
      label: t('Settings.webhooks.name'),
      searchType: 'search',
    },
    {
      accessor: 'url',
      label: 'url',
      searchType: '',
    },
    {
      accessor: 'authType',
      label: t('Settings.webhooks.authType'),
      searchType: '',
    },
    {
      accessor: 'actions',
      label: t('Settings.webhooks.actions'),
      searchType: '',
    },
  ]

  useEffect(() => {
    if (!webhooks) return
    if (webhooks?.total < page * size) {
      setPage(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webhooks, size, page])

  const handleDelete = () => {
    if (!org || !editingWebhook) return
    deleteWebhookMutation(
      { id: editingWebhook.id },
      {
        onSuccess: () => {
          setDeleteModalOpen(false)
          setEditingWebhook(undefined)
        },
      },
    )
  }

  const handleUpdateStatus = (id: string, status: boolean) => {
    if (!org) return
    updateWebhookStatus(
      { organization: org, id: id, value: !status },
      {
        onSuccess: () => {
          setEditingWebhook(undefined)
        },
      },
    )
  }

  const onEditClick = (id: string) => {
    setEditingWebhook(webhooks?.webhooks?.find((webhook) => webhook.id === id))
    setEditModalOpen(true)
  }

  const onEditAccessClick = (id: string) => {
    setEditingWebhook(webhooks?.webhooks?.find((webhook) => webhook.id === id))
    setEditAccessModalOpen(true)
  }

  const onDeleteClick = (id: string) => {
    setEditingWebhook(webhooks?.webhooks?.find((webhook) => webhook.id === id))
    setDeleteModalOpen(true)
  }

  const handleEditCancel = () => {
    setEditModalOpen(false)
    setEditingWebhook(undefined)
  }

  const handleCreate = async (body: any) => {
    addWebhook(
      {
        body: { ...body },
      },
      {
        onSuccess: () => {
          setCreateModalOpen(false)
        },
      },
    )
  }

  return (
    <div className='h-full overflow-hidden flex flex-col'>
      <div className={classNames('flex items-center justify-start px-6 py-4', 'border-b border-gray-300')}>
        <div>
          <p className='px-1 py-2 text-sm text-gray-400'>{`S-Process / ${t('Settings.processes')}`}</p>
          <p className='text-xl px-1'>{t('Settings.settingsMenu.webhooks')}</p>
        </div>
        <div className='ml-16 flex h-full items-end gap-x-3'>
          <Button
            className='text-blue-500 flex items-center gap-x-3 border border-blue-500'
            onClick={() => setCreateModalOpen(true)}
          >
            <PlusIcon className='w-4 h-4' />
            {t('Settings.webhooks.addWebhook')}
          </Button>
        </div>
      </div>
      <div className='grow overflow-auto p-8'>
        <div className='w-full relative h-full overflow-auto'>
          {!isLoading || !isFetching || !isPending ? (
            (webhooks?.webhooks && webhooks?.webhooks?.length > 0) || search !== '' ? (
              <Tabs
                active={activeTab.value}
                tabs={tabs.map((tab) => tab.value)}
                onClick={(string) => {
                  setActiveTab(tabs.find((tab) => tab.value === string)!)
                }}
                childrenClassName='!pt-0'
              >
                <TabPanel tab={tabs[0].value} active={activeTab.value}>
                  <div className='w-full pt-7'>
                    {webhooks?.total && webhooks?.total > 0 && (
                      <div className='flex flex-col'>
                        <div className='flex justify-end'>
                          <PaginationController
                            total={webhooks?.total ?? 1}
                            size={size}
                            page={page + 1}
                            onPageChange={(page) => setPage(page - 1)}
                            onSizeChange={(size) => setSize(size)}
                          />
                        </div>
                        <Table
                          headers={headers}
                          rows={newRows!}
                          onEdit={onEditClick}
                          onDelete={onDeleteClick}
                          onNameSearch={setSearch}
                          onEditAccess={onEditAccessClick}
                          onUpdateStatus={handleUpdateStatus}
                          updatingStatus={updatingWebhook}
                        />
                      </div>
                    )}
                  </div>
                </TabPanel>
                <TabPanel tab={tabs[1].value} active={activeTab.value}>
                  <IncidentsTab />
                </TabPanel>
              </Tabs>
            ) : (
              <p className='text-gray-500'>{t('Settings.webhooks.noWebhooks')}</p>
            )
          ) : (
            <div className='w-full flex justify-center inset-x-auto absolute '>
              <SpinnerIcon className='w-8 h-8 text-sky-500' />
            </div>
          )}
        </div>
      </div>
      {createModalOpen && (
        <AddWebhookModal
          open={createModalOpen}
          setOpen={setCreateModalOpen}
          onCancel={() => setCreateModalOpen(false)}
          onCreate={handleCreate}
          creating={addingWebhook}
        />
      )}
      {editModalOpen && (
        <EditWebhookModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          onCancel={handleEditCancel}
          editingWebhook={editingWebhook}
        />
      )}
      {editAccessModalOpen && (
        <EditAccessModal
          open={editAccessModalOpen}
          webhook={editingWebhook}
          setOpen={setEditAccessModalOpen}
          onCancel={() => {
            setEditAccessModalOpen(false)
            setEditingWebhook(undefined)
          }}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title={t('Settings.webhooks.deleteWebhook')}
          description={t('Settings.webhooks.deleteWebhookMsg')}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDeleteConfirmed={handleDelete}
          isLoading={removingWebhook}
        />
      )}
    </div>
  )
}

export default WebhooksPage
