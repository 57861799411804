import { Button, DatePicker, Option, RestartIcon, Select, SpinnerIcon } from '@sistemiv/s-components'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import IncidentReportWrapper from './incident-report/IncidentReportWrapper'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { PriorityIcon } from '@sistemiv/s-components/dist/esm/components/input/Input'
import dayjs from 'dayjs'
import { imageBase } from '../../../services/http-common'
import { Priority, formatPriorityRange, getFilteredPriorities } from '../../../utils/priorityUtils'
import { useTranslation } from 'react-i18next'
import { Incident } from '../../../models/ProcessInstance'
import { useParams } from 'react-router-dom'
import UserService from '../../../services/UserService'
import { AssigneeCombobox } from '../../user-settings/processes-settings/general/UserTasksTab'

type BottomInfoPreviewProps = {
  activityName: string
  dueDate: Date
  assignee: string
  priority: number
  incident?: Incident
  onRestartIncident?(): void
  taskRestarting?: boolean
  onEdit?: (actionString: string, body: any, taskId: string) => void
  activityId: string
  activityType: string
}
const BottomInfoPreview: FC<BottomInfoPreviewProps> = ({
  assignee,
  dueDate,
  activityName,
  priority,
  incident,
  taskRestarting,
  onRestartIncident,
  onEdit,
  activityId,
  activityType,
}) => {
  const [priorityValue, setPriorityValue] = useState<Priority>(formatPriorityRange(priority))
  const [dateValue, setDateValue] = useState<Date | null>(dueDate ? dayjs(dueDate).toDate() : null)
  const [assigneeValue, setAssigneeValue] = useState<any>(null)
  const { t } = useTranslation()
  const { org } = useParams()
  const [orgs, setOrgs] = useState([])
  const [groups, setGroups] = useState([])
  const [users, setUsers] = useState([])
  const priorities = getFilteredPriorities(priorityValue.value)
  const now = useMemo(() => new Date(), [])
  const bottomRef = useRef(null)

  useEffect(() => {
    if (!org) return
    UserService.listAllUsersInOrg(org).then((res) => {
      setUsers(
        res.users.map((item) => ({
          type: 'circled_value',
          id: item.objectId,
          value: item.name,
          icon: `${imageBase}/${item.objectId}/76`,
          tab: 'Users',
        })),
      )
    })
    UserService.listAllGroups(org).then((res) => {
      setGroups(
        res.results.map((item) => ({
          type: 'b64_value',
          id: item.id,
          value: item.name,
          icon: item.icon,
          tab: 'Groups',
        })),
      )
    })
    UserService.listAllOrgNodes(org).then((res) => {
      setOrgs(res.results.map((item) => ({ type: 'value', id: item.id, value: item.name, tab: 'Org chart' })))
    })
  }, [org])

  useEffect(() => {
    //scroll down to focus component
    if (bottomRef.current) {
      //@ts-ignore
      bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [bottomRef, activityName])

  useEffect(() => {
    if (users && orgs && groups) {
      const selectedAssignee = [...users, ...groups, ...orgs].find((o: any) => o.id === assignee)
      setAssigneeValue(selectedAssignee)
    }
  }, [orgs, groups, users, assignee])

  useEffect(() => setPriorityValue(formatPriorityRange(priority)), [priority])
  useEffect(() => setDateValue(dueDate ? dayjs(dueDate).toDate() : null), [dueDate])

  return (
    <div className='flex flex-col mb-2' ref={bottomRef}>
      {taskRestarting ? (
        <div className='bg-[#6275A5] text-white py-2 px-3 mb-4 flex gap-x-8 rounded'>
          <SpinnerIcon className='w-6 h-6 text-sky-500' />
          <p>{t('ProcessInstance.restartingFailed')}</p>
        </div>
      ) : (
        <div className='flex flex-row gap-x-20'>
          <IncidentReportWrapper label={t('ProcessInstance.activity')}>
            <div className='flex flex-row gap-1.5 items-center'>
              <div className='rounded bg-[#DEE1FC] flex items-start p-0.5 px-2 min-w-fit'>{activityName}</div>
              {incident && <ExclamationCircleIcon className='w-5 h-5 text-red-400' />}
              {incident && incident.retriable && (
                <Button onClick={() => onRestartIncident?.()} className='!p-0'>
                  <RestartIcon className='w-5 h-5 text-gray-400 hover:text-gray-700' />
                </Button>
              )}
            </div>
          </IncidentReportWrapper>
          {activityType === 'userTask' && (
            <>
              {onEdit && !incident && (
                <IncidentReportWrapper label={t('ProcessInstance.taskDueDate')}>
                  <DatePicker
                    value={dateValue}
                    editMode={true}
                    inputClassName='!px-0'
                    onChange={(date) => {
                      setDateValue(date)
                      onEdit('due-date', { dueDate: date }, activityId)
                    }}
                    topMenu={true}
                    min={now}
                  />
                </IncidentReportWrapper>
              )}
              {onEdit && !incident && (
                <IncidentReportWrapper label={t('ProcessInstance.taskAssignee')}>
                  <AssigneeCombobox
                    options={[...users, ...groups, ...orgs]}
                    selected={assigneeValue}
                    type='edit'
                    onChange={(value) => {
                      console.log(value)
                      onEdit('assignee', { AssigneeId: value.id }, activityId)
                      setAssigneeValue(value)
                    }}
                  />
                </IncidentReportWrapper>
              )}
              {!incident && onEdit && (
                <IncidentReportWrapper label={t('ProcessInstance.taskPriority')}>
                  <Select
                    type='priority'
                    value={priorityValue}
                    topMenu={true}
                    onChange={(e) => {
                      setPriorityValue(e)
                      onEdit('priority', { priority: e.value }, activityId)
                    }}
                    editMode={true}
                  >
                    {priorities
                      .filter((p) => p.value !== priorityValue.value)
                      .map((p) => (
                        <Option key={p.value} value={p}>
                          <div className='flex justify-start items-center gap-x-3'>
                            <PriorityIcon priority={p.label} />
                            {p.label}
                          </div>
                        </Option>
                      ))}
                  </Select>
                </IncidentReportWrapper>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default BottomInfoPreview
