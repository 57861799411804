import { CustomSelect, componentType } from './CustomSearchSelect'

class CustomFormFields {
  constructor(formFields) {
    formFields.register(componentType, CustomSelect)
  }
}

export default {
  __init__: ['selectField'],
  selectField: ['type', CustomFormFields],
}
