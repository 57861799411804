import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import WebhooksService from '../../../../../services/Webhooks.service'

export const useAddWebhook = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, { body: { name: string; authType: string } }>({
    mutationKey: ['add-webhook'],
    mutationFn: (payload) => WebhooksService.addWebhook(org!, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['webhooks'] })
    },
  })
}
