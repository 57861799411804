import { BulkActionModal, SpinnerIcon } from '@sistemiv/s-components'
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import LeftCollapseLayout from '../layout/LeftCollapseLayout'
import { CompleteTaskInProgress } from '@sistemiv/s-components/dist/esm/components/bulk-action-modal/CompleteTaskInProgress'
import {
  useProcessInstances,
  useProcessInstanceTaskRetry,
  useFilters,
  useFilterDefinition,
  useTaskComplete,
} from '../../repositories'
import { mapProcessInstance } from '../../utils/mappers'
import { useProcessInstanceCompleteTask } from '../../repositories/process-instances/mutations/process-instance-complete-task.mutation'
import { useProcessInstancesBulkUpdate } from '../../repositories/process-instances/mutations/process-instance-bulk-update'
import { useProcessFields } from '../../repositories/settings/processes/processes/process-fields.repository'
import { FieldT } from '@sistemiv/s-components/dist/esm/components/table-fields-filter/TableFieldsFilter'
import CustomFilters from './CustomFilters'
import ProcessInstancesService from '../../services/ProcessInstances.service'
import TableProcess, { ProcessTableRow } from './table/TableProcess'
import dayjs from 'dayjs'
import ProcessInstanceHeader from './ProcessInstanceHeader'
import { useListAllUsersInOrg } from '../../repositories/settings/processes/processes/list-all-users-in-org.repository'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import { ProcessInstancesContext } from '../../context/ProcessInstancesContextProvider'
import { SignalRContext } from '../../pages/Dashboard'
import { useQueryClient } from '@tanstack/react-query'
import { AssigneeCandidateType } from '../../models/ProcessInstance'
import exportTableData from '../../utils/exportTableData'
import UserTaskFormModal from './UserTaskFormModal'

const ProcessInstances: FC = () => {
  const { org, type } = useParams()
  const location = useLocation()
  const prevLocation = useRef(location)
  const { instance } = useMsal()
  const userId = useMemo(() => instance.getActiveAccount()?.localAccountId, [instance])
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedRows, setSelectedRows] = useState<ProcessTableRow[]>([])
  const [bulkActionOpen, setBulkActionOpen] = useState(false)
  const [bulkAction, setBulkAction] = useState('')
  const [unclaimedTasksOpen, setUnclaimedTasksOpen] = useState(false)
  const [showDone, setShowDone] = useState(true)
  const [completing, setCompleting] = useState(false)
  const [selectedFields, setSelectedFields] = useState<FieldT[]>([])
  const [completedTaskCount, setCompletedTaskCount] = useState(0)
  const [errorTaskCount, setErrorTaskCount] = useState(0)
  const [appliedFilters, setAppliedFilters] = useState<string[]>([])
  const { headerFilters, setHeaderFilters, fieldFilters, setFieldFilters } = useContext(ProcessInstancesContext)
  const { data: usersInOrg } = useListAllUsersInOrg()
  const [restartedTasks, setRestartedtasks] = useState<string[]>([])
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [selectedUserTask, setSelectedUserTask] = useState('')
  const [userTaskModalOpen, setUserTaskModalOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const [tableExportLoading, setTableExportLoading] = useState(false)
  const { mutateAsync: taskCompleteMutation } = useTaskComplete()
  const { data: filterDefinition } = useFilterDefinition({
    organization: org,
    key: type,
    id: searchParams.get('activeFilter') ?? undefined,
  })
  const { data: filters } = useFilters({ organization: org, key: type })
  const { data: users } = useListAllUsersInOrg()
  const {
    data: processInstancesRawData,
    refetch,
    isLoading: isProcessInstanceLoading,
    isFetching,
  } = useProcessInstances({
    page: parseInt(searchParams.get('page') ?? '1'),
    size: parseInt(searchParams.get('pageSize') ?? '20'),
    params: {
      filter: !showDone
        ? ['resolution:eql=null', ...appliedFilters, ...fieldFilters]
        : [...appliedFilters, ...fieldFilters],
      order: ['created:dsc'],
    },
  })
  const {
    data: fields = [],
    isLoading: isLoadingFields,
    isFetching: isFetchingFields,
    isError: fieldsError,
  } = useProcessFields({ organization: org, key: type })
  const processInstances = useMemo(() => {
    console.log('memo')
    if (isProcessInstanceLoading || isFetching) return
    return processInstancesRawData && fields && !fieldsError
      ? processInstancesRawData?.results.map((row) => {
          const retObj = mapProcessInstance(
            row,
            fields.filter((f) => f.scope === 'Variable').map((f) => f.id),
            users?.users,
          )
          return retObj
        })
      : []
  }, [processInstancesRawData, fields, users?.users, fieldsError, isProcessInstanceLoading, isFetching])

  const { mutateAsync: completeTaskMutation } = useProcessInstanceCompleteTask()
  const { mutateAsync: retryTaskMutation } = useProcessInstanceTaskRetry()
  const { mutate: bulkUpdate } = useProcessInstancesBulkUpdate()
  const queryClient = useQueryClient()
  SignalRContext.useSignalREffect(
    'sync',
    //@ts-ignore
    (eventName, data) => {
      console.log(eventName)
      console.log(data)
      if (
        ['ProcessInstanceStartedEvent'].includes(eventName) &&
        data.UserId !== userId &&
        data.ProcessDefinitionKey === type
      ) {
        !showNotification && setShowNotification(true)
      }
      if (
        ['ProcessInstanceEndedEvent'].includes(eventName) &&
        data.UserId !== userId &&
        processInstancesRawData?.results.find((instance) => instance.id === data.ProcessInstanceId) &&
        data.ProcessDefinitionKey === type
      ) {
        !showNotification && setShowNotification(true)
      }
      if (
        [
          // 'UserTaskAssigneeChangedEvent',
          'UserTaskUpdatedEvent',
          'UserTaskEndedEvent',
          'UserTaskStartedEvent',
          'ProcessInstanceDueDateChangedEvent',
          'ProcessInstancePriorityChangedEvent',
          'ServiceTaskInstanceStartedEvent',
          'ServiceTaskInstanceCompletedEvent',
          'ServiceTaskInstanceFailedEvent',
          'UserTaskInstanceClaimedEvent',
        ].includes(eventName) &&
        processInstancesRawData?.results?.map((instance) => instance.id === data.ProcessInstanceId)
      ) {
        console.log('update')
        const newDataArray = processInstancesRawData?.results?.map((instance) => {
          if (instance.id === data.ProcessInstanceId) {
            switch (eventName) {
              case 'ServiceTaskInstanceFailedEvent':
                setRestartedtasks((old) => old.filter((el) => el !== data.ProcessInstanceId))
                return {
                  ...instance,
                  incidents: [
                    {
                      activityInstanceId: data.ActivityInstanceId,
                      description: data.Description,
                      errorCode: data.Code,
                      failedAt: data.FailedAt,
                      message: data.Message,
                      retry: data.Retry,
                      retryTimeout: data.RetryTimeout,
                    },
                  ],
                }
              case 'UserTaskEndedEvent':
                return {
                  ...instance,
                  activities: instance.activities.filter((act) => act.activityInstanceId !== data.ActivityInstanceId),
                }
              case 'UserTaskUpdatedEvent':
                return {
                  ...instance,
                  activities: instance.activities.map((act) =>
                    act.activityInstanceId === data.ActivityInstanceId
                      ? {
                          ...act,
                          assigneeId: data.AssigneeId,
                          dueDate: data.DueDate,
                          priority: data.Priority,
                          assigneeType: AssigneeCandidateType[data.AssigneeType],
                        }
                      : act,
                  ),
                }
              case 'UserTaskInstanceClaimedEvent':
                return {
                  ...instance,
                  activities: instance.activities.map((act) =>
                    act.activityInstanceId === data.ActivityInstanceId
                      ? {
                          ...act,
                          assigneeId: data.AssigneeId,
                        }
                      : act,
                  ),
                }
              case 'UserTaskStartedEvent':
                return {
                  ...instance,
                  activities:
                    instance?.activities && instance.activities?.length > 0
                      ? [
                          ...instance.activities,
                          {
                            activityId: data.ActivityId,
                            activityInstanceId: data.ActivityInstanceId,
                            activityName: data.ActivityName,
                            activityType: data.ActivityType,
                            assigneeId: data.AssigneeId,
                            priority: data.Priority,
                            started: data.Timestamp,
                          },
                        ]
                      : [
                          {
                            activityId: data.ActivityId,
                            activityInstanceId: data.ActivityInstanceId,
                            activityName: data.ActivityName,
                            activityType: data.ActivityType,
                            assigneeId: data.AssigneeId,
                            priority: data.Priority,
                            started: data.Timestamp,
                          },
                        ],
                }
              case 'ServiceTaskInstanceStartedEvent':
                return {
                  ...instance,
                  activities:
                    instance?.activities && instance.activities?.length > 0
                      ? [
                          ...instance.activities,
                          {
                            activityId: data.ActivityId,
                            activityInstanceId: data.ActivityInstanceId,
                            activityName: data.ActivityName,
                            activityType: data.ActivityType,
                          },
                        ]
                      : [
                          {
                            activityId: data.ActivityId,
                            activityInstanceId: data.ActivityInstanceId,
                            activityName: data.ActivityName,
                            activityType: data.ActivityType,
                          },
                        ],
                }
              case 'ServiceTaskInstanceCompletedEvent':
                return {
                  ...instance,
                  incidents:
                    instance?.incidents && instance?.incidents?.length > 0
                      ? instance.incidents.filter((act) => act.activityInstanceId !== data.ActivityInstanceId)
                      : [],
                  activities: instance.activities.filter((act) => act.activityInstanceId !== data.ActivityInstanceId),
                }
              case 'ProcessInstanceDueDateChangedEvent':
                return { ...instance, dueDate: data.DueDate }
              case 'ProcessInstancePriorityChangedEvent':
                return { ...instance, priority: data.Priority }
              default:
                return instance
            }
          }
          return instance
        })
        const newData = { ...processInstancesRawData, results: [...newDataArray] }
        console.log(newData)
        queryClient.setQueryData(
          [
            'process-instances',
            org,
            type,
            {
              page: parseInt(searchParams.get('page') ?? '1'),
              size: parseInt(searchParams.get('pageSize') ?? '20'),
              filter: !showDone
                ? ['resolution:eql=null', ...appliedFilters, ...fieldFilters]
                : [...appliedFilters, ...fieldFilters],
              order: ['created:dsc'],
            },
          ],
          newData,
        )
      }
    },
    [],
  )

  useEffect(() => {
    prevLocation.current = location
  }, [location])

  const populateSelectedFields = useCallback(() => {
    if (fields.length) {
      const fieldsCopy = [
        ...fields.filter((field) => field.scope === 'Global'),
        ...fields.filter((field) => field.scope === 'Variable'),
        ...fields.filter((field) => field.scope === 'Activity'),
      ]
      const newSelectedFields = fieldsCopy.map((field) => {
        if (field.scope === 'Global' || field.scope === 'Variable') {
          field = { ...field, scope: 'Process instance' }
        }
        if (field.name === 'Task') {
          field = { ...field, name: 'Activity' }
        }
        return field
      })
      setSelectedFields(newSelectedFields)
      localStorage.setItem(type! + org + userId, JSON.stringify({ fields: newSelectedFields, showDone: showDone }))
    }
  }, [fields, userId, showDone, type, org])

  useEffect(() => {
    if (isLoadingFields || fieldsError) return
    const storedData = localStorage.getItem(type! + org + userId)
    if (!storedData) {
      populateSelectedFields()
    } else {
      const parsedData = JSON.parse(storedData)
      setShowDone(parsedData.showDone ?? true)
      parsedData.fields.length > 0 ? setSelectedFields(parsedData.fields) : populateSelectedFields()
    }
  }, [type, org, populateSelectedFields, isLoadingFields, userId, fieldsError])

  const handleSelectedFieldsChange = (fields: FieldT[]) => {
    setSelectedFields(fields)
    localStorage.setItem(type! + org + userId, JSON.stringify({ fields: fields, showDone: showDone }))
  }

  const handleShowDone = () => {
    setShowDone((prev) => !prev)
    localStorage.setItem(type! + org + userId, JSON.stringify({ fields: selectedFields, showDone: !showDone }))
  }

  useEffect(() => {
    const temp = Object.keys(headerFilters)
      .filter((key) => {
        const value = Array.isArray(headerFilters?.[key].value)
          ? (headerFilters?.[key].value as string[]).join(',')
          : headerFilters?.[key].value
        return value && value !== ''
      })
      .map((key) => {
        if (key.toLowerCase().includes('duedate')) {
          const now = dayjs(new Date())
          const durations = headerFilters[key].value.split(' ')
          if (headerFilters[key]?.operation === 'overdue') {
            let before = now.clone()
            for (const duration of durations) {
              before = before.subtract(duration.slice(0, -1), duration.at(-1))
            }
            return `${key}:between=${before.toISOString()},${now.toISOString()}`
          } else if (headerFilters[key].operation === 'duein') {
            let after = now.clone()
            for (const duration of durations) {
              after = after.add(duration.slice(0, -1), duration.at(-1))
            }
            return `${key}:between=${now.toISOString()},${after.toISOString()}`
          }
        }
        return `${key}:${headerFilters?.[key].operation}=${
          Array.isArray(headerFilters?.[key].value)
            ? (headerFilters?.[key].value as string[]).join(',')
            : headerFilters?.[key].value
        }`
      })
    setFieldFilters(temp)
  }, [headerFilters, setFieldFilters])

  const onConfirmBulkEdit = async (value: any, _, action: string) => {
    let body = {}
    const option = action.includes('process-instance')
      ? 'processes'
      : action.includes('user-task')
      ? 'user-tasks'
      : action.includes('service-task')
      ? 'service-tasks'
      : ''

    const actionString = action.includes('due-date')
      ? 'due-date'
      : action.includes('priority')
      ? 'priority'
      : action.includes('retry')
      ? 'retry'
      : action.includes('assignee')
      ? 'assignee'
      : action.includes('complete')
      ? 'complete'
      : action.replace(/[A-Z]/g, '')

    const selectedProcessInstances = selectedRows.map((row) =>
      processInstancesRawData.results.find((el) => el.id === row['id'].value),
    )

    const selectedIds = action.includes('process-instance')
      ? selectedRows.map((row) => row['id'].value)
      : action.includes('user-task')
      ? selectedProcessInstances.map(
          (el) => el.activities.find((task) => task.activityType === 'userTask')?.activityInstanceId,
        )
      : selectedProcessInstances.map(
          (el) => el.activities.find((task) => task.activityType === 'serviceTask')?.activityInstanceId,
        )
    if (action.includes('priority')) {
      switch (value) {
        case 'Highest':
          value = 100
          break
        case 'High':
          value = 75
          break
        case 'Medium':
          value = 50
          break
        case 'Low':
          value = 25
          break
        case 'Lowest':
          value = 0
          break
        default:
          value = undefined
      }

      body = {
        priority: value,
      }
    } else if (action.includes('due-date')) {
      body = {
        dueDate: value,
      }
    } else if (action.includes('assignee')) {
      body = {
        assigneeId: value.objectId,
      }
    }

    if (!type) return
    bulkUpdate(
      { actionString, selectedIds, body, option },
      {
        onSuccess: () => {
          setBulkActionOpen(false)
          setSelectedRows([])
        },
      },
    )

    return
  }

  const handleRefreshData = () => {
    refetch()
    setShowNotification(false)
  }

  const handleExport = async (exportOptions: any) => {
    setTableExportLoading(true)
    if (!org || !type) return
    const accessors = selectedFields.map((f) => f.id)
    let response = await ProcessInstancesService.list(org, type, 1, 20)
    if (response.total > 20) {
      response = await ProcessInstancesService.list(org, type, 1, response.total)
    }
    let exportingInstances = response.results
    if (exportOptions.mode === 'Export selected') {
      exportingInstances = response.results.filter((el) => selectedRows.find((row) => row['id'].value === el.id))
    }
    const data = exportingInstances.map((row) => {
      const mappedObject = mapProcessInstance(
        row,
        fields.filter((f) => f.scope === 'Variable').map((f) => f.id),
        users?.users,
      )
      const object = {}
      accessors.forEach((key) => {
        object[key] = mappedObject?.[key]?.value
      })
      return object
    })
    if (data.length === 0) return
    if (exportOptions.exportHistory) {
      const newData: any[] = []
      const historyPromises = data.map(async (instance) => {
        const processInstanceCode = instance.ProcessInstanceCode
        if (!processInstanceCode) return []

        const id = (
          exportingInstances.find(
            (instanceExporting) => instanceExporting.processInstanceCode === processInstanceCode,
          ) || {}
        )?.id

        try {
          // Fetch histories for the process
          const histories = await ProcessInstancesService.getInstanceHistory(org, id || 'undefined', 'asc')

          return histories.map((history: any) => {
            const mergedHistory = { ...instance, ...history }
            return mergedHistory
          })
        } catch (error) {
          console.error('Error fetching history:', error)
          return [
            {
              ProcessInstanceCode: processInstanceCode,
              Error: 'Error fetching history',
            },
          ]
        }
      })

      const historiesArray = await Promise.all(historyPromises)

      historiesArray.forEach((histories) => {
        newData.push(...histories)
      })

      // Replace data with history-only rows
      data.length = 0
      data.push(...newData)
    }

    exportTableData({
      xlsFormat: exportOptions.exportExcel,
      csvFormat: exportOptions.exportCsv,
      tablePayload: { ProcessInstances: data },
      setTableExportLoading,
    })
  }

  const handleProcessFields = () => {
    const fieldsCopy = [
      ...fields.filter((field) => field.scope === 'Global'),
      ...fields.filter((field) => field.scope === 'Variable'),
      ...fields.filter((field) => field.scope === 'Activity'),
    ]

    return fieldsCopy.map((field) => {
      if (field.scope === 'Global' || field.scope === 'Variable') {
        field = { ...field, scope: 'Process instance' }
      }
      if (field.name === 'Task') {
        field = { ...field, name: 'Activity' }
      }
      return field
    })
  }

  useEffect(() => {
    if (type && prevLocation.current.pathname.includes(type)) return
    setHeaderFilters({})
  }, [type, setHeaderFilters])

  useEffect(() => {
    if (completedTaskCount > 0) {
      setCompleting(true)
    }
    if (errorTaskCount > 0) {
      setCompleting(false)
    }
  }, [completedTaskCount, errorTaskCount])

  const handleFilterChange = (field: string, operation: string, value: any) => {
    setHeaderFilters((old) => ({
      ...old,
      [field]: { operation, value },
    }))
  }

  const handleRetryTask = async (taskId: string) => {
    try {
      await retryTaskMutation({ taskId })
    } catch (error) {
      console.error('error')
    }
  }

  useEffect(() => {
    if (isLoadingFields || isFetchingFields || fieldsError) return
    if (!searchParams.get('activeFilter')) {
      setAppliedFilters([])
      return
    }
    const tempFilters: string[] = []
    filterDefinition?.forEach((fd) => {
      const field = fields.find((f) => f.id === fd.field)

      const searchFieldId = field?.processInstanceField.toLowerCase().includes('activities')
        ? 'activities.activityId'
        : field?.scope.toLowerCase().includes('activity')
        ? `activities.${field.processInstanceField}`
        : field?.scope.toLowerCase().includes('variable')
        ? `typedValues.${field.processInstanceField}`
        : field?.processInstanceField
      if (field?.processInstanceField.toLowerCase().includes('duedate')) {
        const now = dayjs(new Date())
        const durations = fd.value.split(' ')
        if (fd?.operation === 'overdue') {
          let before = now.clone()
          for (const duration of durations) {
            before = before.subtract(duration.slice(0, -1), duration.at(-1))
          }
          tempFilters.push(`${searchFieldId}:between=${before.toISOString()},${now.toISOString()}`)
        } else if (fd.operation === 'duein') {
          let after = now.clone()
          for (const duration of durations) {
            after = after.add(duration.slice(0, -1), duration.at(-1))
          }
          tempFilters.push(`${searchFieldId}:between=${now.toISOString()},${after.toISOString()}`)
        }
      } else {
        tempFilters.push(
          `${searchFieldId}:${fd.operation}=${Array.isArray(fd.value) ? (fd.value as string[]).join(',') : fd.value}`,
        )
      }
    })
    setAppliedFilters(tempFilters)
  }, [filterDefinition, searchParams, fields, isLoadingFields, isFetchingFields, fieldsError])

  const handleBulkComplete = async () => {
    if (selectedRows.length > 0 && selectedRows[0]['activityType']?.value.includes('userTask')) {
      const activityId = selectedRows[0]['activityInstanceId'].value
      setSelectedUserTask(activityId)
      setUserTaskModalOpen(true)
    } else {
      setUnclaimedTasksOpen(true)
      setCompletedTaskCount(0)
      setErrorTaskCount(0)

      for (const r of processInstancesRawData?.results.filter((r) =>
        selectedRows.find((row) => row['id'].value === r.id),
      ) ?? []) {
        await completeTaskMutation(
          { id: r.activities?.[0]?.activityInstanceId },
          {
            onSuccess: () => {
              setCompletedTaskCount((old) => old + 1)
            },
            onError: () => {
              setErrorTaskCount((old) => old + 1)
            },
          },
        )
      }

      setSelectedRows([])
    }
  }

  const handleUsertasksBulkComplete = async (body) => {
    setUnclaimedTasksOpen(true)
    setCompletedTaskCount(0)
    setErrorTaskCount(0)

    for (const r of processInstancesRawData?.results.filter((r) =>
      selectedRows.find((row) => row['id'].value === r.id),
    ) ?? []) {
      await taskCompleteMutation(
        { id: r.activities?.[0]?.activityInstanceId, body: body },
        {
          onSuccess: () => {
            setCompletedTaskCount((old) => old + 1)
          },
          onError: () => {
            setErrorTaskCount((old) => old + 1)
          },
        },
      )
    }
    //queryClient.invalidateQueries({ queryKey: ['process-instances'] })
    setUserTaskModalOpen(false)
    setSelectedRows([])
  }

  useEffect(() => {
    if (!processInstancesRawData) return
    const page = parseInt(searchParams.get('page') ?? '1')
    const size = parseInt(searchParams.get('pageSize') ?? '20')
    if (processInstancesRawData?.total < (page - 1) * size) {
      setSearchParams({ pageSize: `${size}`, page: '1' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processInstancesRawData, searchParams])

  if (isProcessInstanceLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <SpinnerIcon className='text-sky-500 h-7 w-7' />
      </div>
    )

  if (tableExportLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <div className='flex flex-col gap-y-2 items-center justify-center'>
          <SpinnerIcon className='text-sky-500 h-7 w-7' />
          {t('ProcessInstance.exportingTable')}
        </div>
      </div>
    )

  return (
    <LeftCollapseLayout>
      {filters?.length ? (
        <div className='max-w-xs w-full h-full border-r border-gray-200 p-3'>
          <CustomFilters />
        </div>
      ) : (
        <div />
      )}
      <div className='flex flex-col overflow-hidden '>
        <div className='px-8 py-3 border-b border-gray-300 flex-none'>
          <ProcessInstanceHeader
            total={processInstancesRawData?.total}
            size={parseInt(searchParams.get('pageSize') ?? '20')}
            page={parseInt(searchParams.get('page') ?? '1')}
            fields={handleProcessFields()}
            selectedFields={selectedFields}
            selectedRows={selectedRows}
            onSelectedFieldsChange={handleSelectedFieldsChange}
            onRestoreFields={populateSelectedFields}
            showNotification={showNotification}
            onRefresh={handleRefreshData}
            setShowNotification={setShowNotification}
            onPageChange={(value) =>
              setSearchParams({ pageSize: searchParams.get('pageSize') ?? '20', page: `${value}` })
            }
            onSizeChange={(value) => setSearchParams({ page: searchParams.get('page') ?? '1', pageSize: `${value}` })}
            onExport={handleExport}
            showDoneTasks={showDone}
            setShowDoneTasks={handleShowDone}
            onBulkActionClicked={(action) => {
              if (action !== 'attachmentsPI') {
                setBulkAction(action)
                setBulkActionOpen(true)
              }
            }}
            onCompleteTasksClicked={handleBulkComplete}
          />
        </div>
        {!fieldsError && (
          <div className='px-8 py-2 overflow-auto grow relative'>
            <div className={`${isFetching ? 'opacity-50' : 'opacity-100'}`}>
              <TableProcess
                rows={processInstances}
                headers={fields
                  .filter((h) => selectedFields?.findIndex((sf) => sf?.id === h?.id) > -1)
                  .map((h) => {
                    return {
                      ...h,
                      searchType: h.itemType?.includes('AbsoluteDate')
                        ? 'rangePicker'
                        : h.itemType?.includes('RelativeDate')
                        ? 'dueDate'
                        : h.processInstanceField.includes('Assignee') || h.itemType?.includes('Assignee')
                        ? 'assignee'
                        : h.itemType?.includes('Multiselect')
                        ? 'select'
                        : h.itemType?.includes('Number')
                        ? 'exact'
                        : 'like',
                    }
                  })}
                filters={[!showDone ? 'resolution:eql=null' : '', ...appliedFilters, ...fieldFilters]}
                headerFilters={headerFilters}
                onTableRowClick={(id) => navigate({ pathname: `/${org}/process-instance/${type}/${id}` })}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onRestartFailedTask={handleRetryTask}
                onSearch={handleFilterChange}
                restartedTasks={restartedTasks}
                setRestartedTasks={setRestartedtasks}
              />
            </div>
            {!processInstancesRawData?.total && !isFetching && (
              <p className='w-full'>{t('ProcessInstance.noProcesses')}</p>
            )}
          </div>
        )}
        {userTaskModalOpen && (
          <UserTaskFormModal
            processInstanceId={selectedRows[0]['id'].value}
            open={userTaskModalOpen}
            setOpen={setUserTaskModalOpen}
            taskId={selectedUserTask[0]}
            handleBulkComplete={handleUsertasksBulkComplete}
          />
        )}
        {bulkActionOpen && (
          <BulkActionModal
            isOpen={bulkActionOpen}
            setOpen={setBulkActionOpen}
            action={bulkAction}
            onConfirm={onConfirmBulkEdit}
            assignees={usersInOrg.users}
          />
        )}
        {unclaimedTasksOpen && (
          <CompleteTaskInProgress
            isOpen={unclaimedTasksOpen}
            setOpen={setUnclaimedTasksOpen}
            selectedInstances={processInstances?.filter((o) =>
              selectedRows?.find((row) => row['id'].value === o['id'].value),
            )}
            completing={completing}
            percent={
              completedTaskCount > 0
                ? Math.round((completedTaskCount / (completedTaskCount + errorTaskCount)) * 100)
                : 0
            }
            completedTaskCount={completedTaskCount}
            taskCount={
              processInstances?.filter((o) => selectedRows?.find((row) => row['id'].value === o['id'].value)).length ??
              0
            }
          />
        )}
      </div>
    </LeftCollapseLayout>
  )
}

export default ProcessInstances
