import { axiosIamInstance, axiosWebhooksInstance } from './http-common'

class WebhooksService {
  async list(organization: string, page: number, size: number, search?: string) {
    return (
      await axiosWebhooksInstance.get(`${organization}/webhooks`, {
        params: {
          page: page,
          size,
          search,
        },
        paramsSerializer: { indexes: null },
      })
    ).data
  }

  async listIncidents(
    organization: string,
    page: number,
    size: number,
    nameSearch?: string,
    eventSearch?: string,
    dataSearch?: string,
    errorSearch?: string,
  ) {
    return (
      await axiosWebhooksInstance.get(`${organization}/incidents`, {
        params: {
          page: page,
          size,
          webhookName: nameSearch,
          event: eventSearch,
          errorMessage: errorSearch,
          payload: dataSearch,
        },
        paramsSerializer: { indexes: null },
      })
    ).data
  }

  async listAccess(organization: string, id: string, page: number, size: number) {
    return (
      await axiosWebhooksInstance.get(`${organization}/webhooks/${id}/access`, {
        params: {
          page: page - 1,
          size,
        },
        paramsSerializer: { indexes: null },
      })
    ).data
  }

  async listServiceAccounts(organization: string, page: number, size: number) {
    return (
      await axiosIamInstance.get(`${organization}/service-accounts`, {
        params: {
          page: page - 1,
          size,
        },
        paramsSerializer: { indexes: null },
      })
    ).data
  }

  addWebhook = async (organization: string, body: { name: string; authType: string }): Promise<any> => {
    return (await axiosWebhooksInstance.post(`/${organization}/webhooks`, body)).data
  }

  async deleteWebhook(organization: string, id: string) {
    return (await axiosWebhooksInstance.delete(`/${organization}/webhooks/${id}`)).data
  }

  async updateWebhookStatus(id: string, organization: string, status: boolean) {
    return (await axiosWebhooksInstance.post(`/${organization}/webhooks/${id}`, { enabled: status })).data
  }

  async updateWebhookName(id: string, organization: string, name: string) {
    return (await axiosWebhooksInstance.post(`/${organization}/webhooks/${id}/rename`, { name: name })).data
  }

  async updateWebhookAuthType(id: string, organization: string, type: string) {
    return (await axiosWebhooksInstance.post(`/${organization}/webhooks/${id}/auth-type`, { authType: type })).data
  }

  async updateWebhookAccess(id: string, organization: string, value: string[]) {
    return (await axiosWebhooksInstance.post(`/${organization}/webhooks/${id}/access`, { serviceAccountIds: value }))
      .data
  }

  async removeWebhookAccess(organization: string, id: string, value: string) {
    return (
      await axiosWebhooksInstance.delete(`/${organization}/webhooks/${id}/access`, {
        data: { serviceAccountId: value },
      })
    ).data
  }
}

export default new WebhooksService()
