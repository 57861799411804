import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import WebhooksService from '../../../../services/Webhooks.service'
import { WebhooksResponse } from '../../../../models/Webhooks'

type WebhooksOptions = {
  page: number
  size: number
  search?: string
}

export const useWebhooks = ({ page, size, search }: WebhooksOptions) => {
  const { org } = useParams()
  return useQuery<WebhooksResponse>({
    queryKey: ['webhooks', org, { page, size, search }],
    queryFn: () => WebhooksService.list(org!, page, size, search),
    placeholderData: keepPreviousData,
    enabled: !!org,
  })
}
