import React, { FC, useEffect, useState } from 'react'
import { Button, DataField, Input, SpinnerIcon, ThrashBinIcon } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { PencilIcon } from '@heroicons/react/24/outline'
import { TableRow } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { Webhook } from '../../../../models/Webhooks'
import { Switch } from '@headlessui/react'

export type TableHeaders = {
  accessor: string
  label: string
  searchType: string
  options?: any[]
  searchValue?: string
}

type TableProps = {
  headers: TableHeaders[]
  rows: TableRow[]
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  onEditAccess: (id: string) => void
  onUpdateStatus: (id: string, status: boolean) => void
  updatingStatus: boolean
  onNameSearch: (value: string) => void
}

const Table: FC<TableProps> = ({
  headers,
  rows,
  onEdit,
  onDelete,
  onEditAccess,
  onNameSearch,
  onUpdateStatus,
  updatingStatus,
}) => {
  const [tableData, setTableData] = useState<TableRow[]>(rows)
  const { t } = useTranslation()
  const [pendingNameSearch, setPendingNameSearch] = useState('')
  const [editingWebhook, setEditingWebhook] = useState<Webhook | null>(null)

  useEffect(() => {
    setTableData(rows)
  }, [rows])

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      onNameSearch(pendingNameSearch)
    }, 500)

    return () => clearTimeout(delayTyping)
  }, [pendingNameSearch, onNameSearch])

  return (
    <div className='overflow-x-auto'>
      <table className='min-w-full divide-y divide-gray-200 border-collapse'>
        <thead className='text-sm bg-white'>
          <tr className='border-b border-gray-300'>
            {headers?.map((header, index) => (
              <th
                data-testid='table-header'
                key={index}
                scope='col'
                className='px-6 py-3 text-left font-normal text-slate-600 capitalize tracking-wider cursor-pointer'
              >
                <div className='inline-flex items-center justify-start gap-x-3 '>{header.label}</div>
              </th>
            ))}
          </tr>
          <tr className='border-b border-gray-300'>
            {headers?.map((header, index) => (
              <th
                key={index}
                scope='col'
                className='px-6 py-3 text-left font-medium text-gray-500 min-w-[12rem] tracking-wider'
                data-testid='filter'
              >
                {header.searchType === 'search' ? (
                  <div>
                    <Input
                      id={'search'}
                      type={'search'}
                      classes='!rounded-md [&>input]:!border-solid [&>input]:border [&>input]:border-gray-300 focus:[&>input]:border-sky-100 pr-16 min-w-[300px] max-w-72'
                      placeholder={t('Common.search') as string}
                      value={pendingNameSearch}
                      onChange={(e) => setPendingNameSearch(e.target.value)}
                    />
                  </div>
                ) : (
                  // : header.searchType === 'select' ? (
                  //   <div>
                  //     <TableHeaderCombobox
                  //       options={
                  //         header.options?.map((h) => {
                  //           if (typeof h === 'string') {
                  //             return {
                  //               type: 'plainText',
                  //               value: h,
                  //             }
                  //           } else if (typeof h === 'object' && h.id) {
                  //             return {
                  //               type: 'plainText',
                  //               id: h.id,
                  //               value: h.name,
                  //             }
                  //           } else {
                  //             return {
                  //               type: 'plainText',
                  //               value: '',
                  //             }
                  //           }
                  //         }) ?? []
                  //       }
                  //       value={filterValues[header.accessor] ?? []}
                  //       selectFor={''}
                  //       onChange={(val) => onHeaderFilter && onHeaderFilter(header.accessor, val)}
                  //     />
                  //   </div>
                  // )
                  <div></div>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='bg-white'>
          {tableData?.map((row, index) => (
            <tr key={index} className='border-b border-gray-300 group hover:bg-sky-50' data-testid='row'>
              {headers?.map((header, index) => {
                const field = header.accessor !== 'actions' ? row[header.accessor] : null
                if (header.accessor === 'actions') {
                  return (
                    <td
                      key={index}
                      className='px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-start'
                      data-testid='table-field'
                    >
                      <div className='flex items-center'>
                        <div className='group/switch flex items-center justify-center gap-x-2  mr-3'>
                          {editingWebhook && editingWebhook.id === row['id'].value && updatingStatus && (
                            <SpinnerIcon className='w-3 h-3 text-blue-500' />
                          )}
                          <Switch
                            disabled={editingWebhook?.id === row['id'].value && updatingStatus}
                            checked={row['enabled'].value}
                            onChange={() => {
                              setEditingWebhook({
                                id: row['id'].value,
                                enabled: row['enabled'].value,
                                url: row['url'].value,
                                name: row['name'].value,
                                authType: row['authType'].value,
                              })
                              onUpdateStatus(row['id'].value, row['enabled'].value)
                            }}
                            className={`border ${
                              row['enabled'].value ? 'border-blue-500' : 'border-gray-400'
                            } relative inline-flex h-[12px] w-[24px] shrink-0 cursor-pointer rounded-full border-2 transition-colors 
                      duration-50 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white disabled:opacity-50
                      focus-visible:ring-opacity-75 disabled:cursor-default`}
                          >
                            <span className='sr-only'>Use setting</span>
                            <span
                              aria-hidden='true'
                              className={`${row['enabled'].value ? 'translate-x-3' : 'translate-x-0'}
                                    pointer-events-none inline-block h-[8px] w-[8px] transform rounded-full border ${
                                      row['enabled'].value ? 'bg-blue-500' : 'bg-gray-400'
                                    } shadow-lg ring-0 transition duration-50 ease-in-out`}
                            />
                          </Switch>
                          {!editingWebhook && (
                            <span className='absolute w-max top-full mt-1.5 scale-0 rounded bg-slate-500 p-2 text-xs z-20 text-white group-hover/switch:scale-100'>
                              {row['enabled'].value
                                ? t('Settings.webhooks.disableWebhook')
                                : t('Settings.webhooks.enableWebhook')}
                            </span>
                          )}
                        </div>
                        <Button
                          className='!p-0 mr-3'
                          onClick={() => {
                            onEdit(row['id']?.value)
                          }}
                        >
                          <PencilIcon className='w-4 h-4 text-gray-500 hover:text-gray-800' />
                        </Button>
                        <Button
                          className='!p-0'
                          onClick={() => {
                            onDelete(row['id']?.value)
                          }}
                        >
                          <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                        </Button>
                        {row['authType']?.value !== 'None' && (
                          <Button
                            className='!p-0 text-blue-500 ml-2'
                            onClick={() => {
                              onEditAccess(row['id']?.value)
                            }}
                          >
                            {t('Settings.webhooks.editAccess')}
                          </Button>
                        )}
                      </div>
                    </td>
                  )
                } else {
                  return (
                    <td
                      key={index}
                      className='px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-start'
                      data-testid='table-field'
                    >
                      <DataField field={field!} />
                    </td>
                  )
                }
              })}
              <td className='p-3'></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table
