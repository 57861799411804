import classNames from 'classnames'

/*
 * Import components and utilities from our extension API. Warning: for demo experiments only.
 */
import { Errors, FormContext, Description, Label, Select } from '@bpmn-io/form-js'
import { useState, useEffect, useRef } from 'preact/hooks'
import { html, useContext } from 'diagram-js/lib/ui'
import debounce from 'lodash.debounce'

export const componentType = 'custom-select'
import './styles.css'

/*
 * This is the rendering part of the custom field. We use `htm` to
 * to render our components without the need of extra JSX transpilation.
 */
export function CustomSelect(props) {
  const { disabled, errors = [], field, readonly, url } = props
  const { description, id, label, validate = {} } = field
  const [options, setOptions] = useState([])
  const [search, setSearch] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const { required } = validate
  const componentRef = useRef(null)
  const { formId } = useContext(FormContext)

  const errorMessageId = errors.length === 0 ? undefined : `${prefixId(id, formId)}-error-message`

  //TO DO: accept url from field params
  const urlFetch = url ?? 'http://universities.hipolabs.com/search'

  //To Do: call our service method to fetch data based on url, when implemented on BE
  const fetchOptions = async (query) => {
    if (!urlFetch) return
    const response = await fetch(`${urlFetch}?name=${query}`)
    const data = await response.json()
    data && data instanceof Array && setOptions(data)
    console.log(options)
  }

  const debouncedFetch = debounce(fetchOptions, 300)

  useEffect(() => {
    if (search.length >= 2) {
      debouncedFetch(search)
      setShowOptions(true)
    } else {
      setShowOptions(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  // const handleChange = (e) => {
  //   console.log(e)
  //   props.onChange({ field, value: e.target.value })
  // }

  const handleSelect = (value) => {
    console.log(value)
    setSearch(value.name)
    props.onChange({ field, value })
    setShowOptions(false)
  }

  const toggleDropdown = () => {
    if (!readonly) setShowOptions((prev) => !prev)
  }

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setShowOptions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return html`<div ref=${componentRef} class=${formFieldClasses(componentType)}>
    <${Label} class="fjs-form-field-label" label=${label} required=${required} />
    <div class="fjs-input-group">
      <input
        type="text"
        value=${search || ''}
        class="custom-select fjs-input "
        disabled=${disabled || readonly}
        onChange=${(e) => {
          setSearch(e.target.value)
          //setShowOptions(false)
        }}
        onClick=${toggleDropdown}
        placeholder="Type to search..."
      />
      ${showOptions &&
      html`<ul className="options-list">
        ${options.map(
          (option) =>
            html`<li key=${option.name} class="option-item" onClick=${() => handleSelect(option)}>${option.name}</li>`,
        )}
      </ul>`}
    </div>
    <${Description} description=${description} />
    <${Errors} errors=${errors} id=${errorMessageId} />
  </div>`
}

/*
 * This is the configuration part of the custom field. It defines
 * the schema type, UI label and icon, palette group, properties panel entries
 * and much more.
 */

CustomSelect.config = {
  /* we can extend the default configuration of existing fields */
  ...Select.config,
  type: componentType,
  label: 'Custom select',
  defaultValue: null,
  organization: '',
  taskId: '',
  iconUrl: 'https://cdn-icons-png.flaticon.com/128/561/561123.png',
  propertiesPanelEntries: ['key', 'label', 'description', 'disabled', 'readonly'],
}

// helper //////////////////////

function formFieldClasses(type, { errors = [], disabled = false, readonly = false } = {}) {
  if (!type) {
    throw new Error('type required')
  }

  return classNames('fjs-form-field', `fjs-form-field-${type}`, {
    'fjs-has-errors': errors.length > 0,
    'fjs-disabled': disabled,
    'fjs-readonly': readonly,
  })
}

function prefixId(id, formId) {
  if (formId) {
    return `fjs-form-${formId}-${id}`
  }

  return `fjs-form-${id}`
}
