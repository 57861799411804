import { useParams } from 'react-router-dom'
import TasksService from '../../services/Tasks.service'
import { useQuery } from '@tanstack/react-query'

type TaskStartFormOptions = {
  id: string
}

export const useTaskStartFrom = (payload: TaskStartFormOptions) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['tasks-form', 'start-form', org, payload.id],
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    queryFn: () => TasksService.getTaskStartForm(org!, payload.id),
    enabled: !!org && !!payload.id && payload.id.length > 3,
  })
}
