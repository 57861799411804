import { Application, OrganizationResponse, UserProfile } from '../models/User'
import { axiosIamInstance } from './http-common'

class UserService {
  async profile(): Promise<UserProfile> {
    return (await axiosIamInstance.get('/profile')).data
  }

  async getUserProfile(id: string): Promise<UserProfile> {
    return (await axiosIamInstance.get(`/profile/${id}`)).data
  }

  async listOrganizations(page = 0, size = 20): Promise<OrganizationResponse> {
    return (await axiosIamInstance.get('/my-organizations', { params: { page, size } })).data
  }

  async listApplications(organization: string): Promise<Application[]> {
    return (await axiosIamInstance.get(`/${organization}/applications/access`)).data
  }

  // Get user groups
  async listGroupsPaginate(organization: string, page: number, size: number, search?: string) {
    const params: { [key: string]: any } = {
      page: page,
      size: size,
    }
    if (search) params.search = search

    return (await axiosIamInstance.get(`/${organization}/group`, { params })).data
  }

  // Get user groups (all)
  async listAllGroups(organization: string) {
    const groups = await this.listGroupsPaginate(organization, 0, 1).then((res) => {
      return res
    })
    if (groups.total <= 1) return groups
    return this.listGroupsPaginate(organization, 0, groups.total)
  }

  // Get organization nodes
  async listOrgNodesPaginate(organization: string, page: number, size: number, search?: string) {
    const params: { [key: string]: any } = {
      page: page,
      size: size,
    }
    if (search) params.search = search

    return (await axiosIamInstance.get(`/${organization}/organization-nodes`, { params })).data
  }

  // Get organization nodes (all)
  async listAllOrgNodes(organization: string) {
    const orgNodes = await this.listOrgNodesPaginate(organization, 0, 1).then((res) => {
      return res
    })
    if (orgNodes.total <= 1) return orgNodes
    return this.listOrgNodesPaginate(organization, 0, orgNodes.total)
  }

  // Get my org node
  async getMyOrgNode(organization: string) {
    return (await axiosIamInstance.get(`/${organization}/organization-nodes/my-organizational-node`)).data
  }

  // Overview all users in an organization
  async listUsersInOrg(organization: string, page: number, size: number, name?: string, email?: string) {
    const params: { [key: string]: any } = {
      page: page,
      size: size,
      statusIds: [4],
    }
    if (name) params.name = name
    if (email) params.email = email

    return (
      await axiosIamInstance.get(`/${organization}/users-overview`, { params, paramsSerializer: { indexes: null } })
    ).data
  }

  // Overview all users in an organization (all)
  async listAllUsersInOrg(organization: string) {
    const users = await this.listUsersInOrg(organization, 0, 1).then((res) => {
      return res
    })
    if (users.total <= 1) return users
    return this.listUsersInOrg(organization, 0, users.total)
  }

  async getUser(organization: string, userId: string) {
    return (await axiosIamInstance.get(`/${organization}/user/${userId}`)).data
  }

  async getUserNodeAndGroups(organization: string) {
    return (await axiosIamInstance.get(`/${organization}/user/groups-and-nodes`)).data
  }
}

export default new UserService()
