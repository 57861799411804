import { GalleryData } from '@sistemiv/s-components/dist/esm/components/process-instance-attachments/AttachmentGalleryModal'
import { TableRow } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { ProcessTableRow } from '@sistemiv/s-components/dist/esm/components/table/TableProcess'
import dayjs from 'dayjs'
import profileIcon from '../assets/icons/customer-2.png'
import systemIcon from '../assets/icons/systemIcon.png'
import {
  ProcessInstance,
  ProcessInstanceLinkedProcesses,
  SubprocessResult,
  ProcessInstanceAttachments,
  ProcessInstanceGallery,
  AssigneeCandidateType,
} from '../models/ProcessInstance'
import { imageBase } from '../services/http-common'

export const mapPriority = (pr: number) => {
  switch (pr) {
    case 0:
      return 'Lowest'
    case 25:
      return 'Low'
    case 50:
      return 'Medium'
    case 75:
      return 'High'
    case 100:
      return 'Highest'
    default:
      return ''
  }
}

export const mapProcessInstance = (
  processInstance: ProcessInstance,
  fields?: string[],
  users?: any[],
): ProcessTableRow => {
  const getUserNameById = (id?: string) => {
    if (!id || !users) return ''
    const user = users?.find((u) => u.objectId === id)
    return user?.name ?? ''
  }
  const retObj: TableRow = {
    id: { type: 'id', value: processInstance.id },
    ProcessInstanceCode: {
      type: 'value',
      value: processInstance.code
        ? processInstance.code
        : processInstance.processInstanceCode
        ? processInstance.processInstanceCode
        : '',
    },
    Name: {
      type: 'value',
      value: processInstance.name ?? '',
    },
    Activity: {
      type: 'solid',
      subType: 'activity',
      value: processInstance.activities?.map((a) => a.activityName) ?? [''],
      solidColor: '#DEE1FC',
    },
    activityInstanceId: { type: 'id', value: processInstance.activities?.map((a) => a.activityInstanceId) },
    activityType: { type: 'id', value: processInstance.activities?.map((a) => a.activityType) },
    Description: {
      type: 'value',
      value: processInstance.description ?? '',
    },
    Version: {
      type: 'value',
      value: processInstance.version ?? '',
    },
    Created: {
      // type: 'value',
      // value: processInstance.created ? dayjs(processInstance.created).format('D MMM YYYY') : '',
      type: 'date',
      value: processInstance.created ? processInstance.created : '',
      dateFormat: 'D MMM YYYY',
    },
    Resolution: { type: 'border', value: processInstance.resolution ?? '', borderColor: '#00C595' },
    Priority: { type: 'priority', value: mapPriority(processInstance.priority) },
    DueDate: {
      // type: 'value',
      // value: processInstance.dueDate ? dayjs(processInstance.dueDate).format('D MMM YYYY') : '',
      type: 'date',
      value: processInstance.dueDate ? processInstance.dueDate : '',
      dateFormat: 'D MMM YYYY',
    },
    ActivityAssignee: {
      type: 'circled_icon',
      tooltip: processInstance.activities?.length
        ? processInstance.activities
            .map((a) =>
              getUserNameById(
                a.assigneeType === AssigneeCandidateType.InstanceStarter ? processInstance.userId : a.assigneeId ?? '',
              ),
            )
            .join()
        : '',
      value: processInstance.activities?.length
        ? processInstance.activities.map((a) =>
            getUserNameById(
              a.assigneeType === AssigneeCandidateType.InstanceStarter ? processInstance.userId : a.assigneeId ?? '',
            ),
          )
        : [''],
      icon: processInstance.activities?.length
        ? processInstance.activities.map((a) =>
            a.assigneeType === AssigneeCandidateType.InstanceStarter
              ? `${imageBase}/${processInstance.userId}/76`
              : `${imageBase}/${a.assigneeId}/76`,
          )
        : [''],
    },
    ActivityDueDate: {
      type: 'value',
      value: processInstance.activities?.length
        ? processInstance.activities.map((a) => (a.dueDate ? dayjs(a.dueDate).format('D MMM YYYY') : ''))
        : '',
    },
    ActivityPriority: {
      type: 'priority',
      value: processInstance.activities?.length ? processInstance.activities.map((a) => mapPriority(a.priority)) : [''],
    },
    incidents: { type: 'value', value: processInstance.incidents ? processInstance.incidents : ['##'] },
  }
  fields?.forEach((fieldId) => {
    if (
      typeof processInstance.typedValues?.[fieldId] === 'object' &&
      !Array.isArray(processInstance.typedValues?.[fieldId]) &&
      processInstance.typedValues?.[fieldId]?.fileName
    )
      retObj[fieldId] = { type: 'value', value: processInstance.typedValues?.[fieldId].fileName }
    else retObj[fieldId] = { type: 'value', value: processInstance.typedValues?.[fieldId]?.toString() }
  })
  return retObj as ProcessTableRow
}

export const mapLinkedProcess = (linkedProcess: ProcessInstanceLinkedProcesses): TableRow => {
  return {
    code: { type: 'box', value: linkedProcess.code, boxColor: linkedProcess.processColor },
    name: { type: 'value', value: linkedProcess.name },
    assignee: { type: 'circled_icon', value: linkedProcess.assignee, icon: profileIcon },
    created: { type: 'date', value: linkedProcess.created, dateFormat: 'DD MMM YYYY' },
    priority: { type: 'priority', value: mapPriority(linkedProcess.priority) },
    linkType: { type: 'value', value: linkedProcess.linkType },
    dueDate: { type: 'date', value: linkedProcess.dueDate, dateFormat: 'DD MMM YYYY' },
    activity: { type: 'solid', value: linkedProcess.activity, solidColor: '#DEE1FC' },
  }
}
;[
  { accessor: 'code', label: 'Code' },
  { accessor: 'name', label: 'Name' },
  { accessor: 'assignee', label: 'Assignee' },
  { accessor: 'created', label: 'Created' },
  { accessor: 'priority', label: 'Priority' },
  { accessor: 'createdBy', label: 'Created by' },
  { accessor: 'dueDate', label: 'Due date' },
  { accessor: 'activity', label: 'Activity' },
]

export const mapSubprocesses = (subprocess: SubprocessResult): TableRow => {
  return {
    code: { type: 'box', value: subprocess.code, boxColor: subprocess.processColor },
    name: { type: 'value', value: subprocess.name },
    assignee: { type: 'circled_icon', value: subprocess.assignee, icon: profileIcon },
    created: { type: 'date', value: subprocess.created, dateFormat: 'DD MMM YYYY' },
    priority: { type: 'priority', value: mapPriority(subprocess.priority) },
    createdBy: { type: 'circled_icon', value: subprocess.createdBy, icon: systemIcon },
    dueDate: { type: 'date', value: subprocess.dueDate, dateFormat: 'DD MMM YYYY' },
    activity: { type: 'solid', value: subprocess.activity, solidColor: '#DEE1FC' },
  }
}

export const mapAttachments = (attachments: ProcessInstanceAttachments[]): any => {
  return attachments.map((attachment) => ({
    id: { type: 'id', value: attachment.attachmentInfo.id },
    dataSection: { type: 'value', value: attachment.variableSection },
    attachment: {
      type: 'image_value',
      value: attachment.attachmentInfo.name,
      image: 'https://picsum.photos/200/300',
    },
    uploaded: {
      type: 'circled_icon',
      value: attachment.uploadedBy,
      icon: profileIcon,
    },
    date: {
      type: 'date',
      value: dayjs(attachment.date.split('T')[0]).toDate(),
      dateFormat: 'DD MMM YYYY HH:MM',
    },
    data: { type: 'value', value: attachment.variable },
  }))
}

export const mapGallery = (attachments: ProcessInstanceGallery[]): GalleryData[] => {
  return [
    {
      section: 'Retail > Regional manager 1 > Store B1',
      items: attachments.map((attachment) => {
        return {
          photoUrl: 'https://picsum.photos/200/300',
          fileName: attachment.attachmentInfo.name,
        }
      }),
    },
  ]
}
