import { useMutation, useQueryClient } from '@tanstack/react-query'
import WebhooksService from '../../../../../services/Webhooks.service'

type UpdateWebhookPayload = {
  organization: string
  id: string
  value: string
}

export const useUpdateWebhookAuthType = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateWebhookPayload>({
    mutationKey: ['webhook-update-auth'],
    mutationFn: (payload) => WebhooksService.updateWebhookAuthType(payload.id, payload.organization, payload.value),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['webhooks'] })
    },
  })
}
