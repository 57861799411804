import React, { FC, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { EditInput, Option, Select } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import ModalDialog from '../../../../ModalDialog'
import { authTypes } from './AddWebhookModal'
import { useUpdateWebhookName } from '../../../../../repositories/settings/processes/webhooks/mutation/webhook-update-name.mutation'
import { useUpdateWebhookAuthType } from '../../../../../repositories/settings/processes/webhooks/mutation/webhook-update-auth.mutation'
import { useParams } from 'react-router-dom'

type EditWebhookProps = {
  open: boolean
  setOpen: DispatchSetAction<boolean>
  editingWebhook: any
  onCancel: () => void
}

const EditWebhookModal: FC<EditWebhookProps> = ({ open, editingWebhook, setOpen, onCancel }) => {
  const [name, setName] = useState(editingWebhook.name)
  const { t } = useTranslation()
  const { org } = useParams()
  const [selectedType, setSelectedType] = useState<string>(editingWebhook.authType)
  const { mutate: updateWebhookName } = useUpdateWebhookName()
  const { mutate: updateWebhookAuthType } = useUpdateWebhookAuthType()

  const handleEditName = (name: string) => {
    updateWebhookName(
      { organization: org!, id: editingWebhook.id, value: name },
      {
        onSuccess: () => {
          setName(name)
        },
      },
    )
  }

  const handleEditAuthType = (type: string) => {
    updateWebhookAuthType(
      { organization: org!, id: editingWebhook.id, value: type },
      {
        onSuccess: () => {
          setSelectedType(type)
        },
      },
    )
  }

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('Settings.webhooks.editWebhook')}
        <button onClick={onCancel}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div>
        <div className='mt-5 space-y-6'>
          <div className='space-y-1'>
            <label className='text-gray-500'>{t('Settings.variables.name')}</label>
            <EditInput value={name} onSave={(value) => org && handleEditName(value)} />
          </div>
        </div>
        <div className='mt-3'>
          <p className='text-xs pb-1 text-gray-600'>{t('Settings.webhooks.authType')}</p>
          <Select
            className='!py-1.5 !rounded'
            value={selectedType}
            displayValue={selectedType}
            onChange={handleEditAuthType}
          >
            {authTypes
              ?.filter((type) => type !== selectedType)
              .map((type) => (
                <Option value={type} key={type}>
                  {type}
                </Option>
              ))}
          </Select>
        </div>
      </div>
    </ModalDialog>
  )
}

export default EditWebhookModal
